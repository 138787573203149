<template>
  <el-aside :width="isCollapse? 'auto' : '210px'" :style="{maxHeight:'100vh', overflowY: 'scroll'}">
    <el-row type="flex" justify="center">
      <el-image
          v-show="!isCollapse"
          style="width: 150px; height: 74px"
          :src="logoPath"></el-image>
    </el-row>
    <el-menu
             :default-active="activeIndex"
             :unique-opened="true"
             :select="changeRouter"
             text-color="#FFFFFF"
             background-color="#409eff"
             active-text-color="#FFFFFF"
             :collapse="isCollapse">
      <component class="menu-item" v-for="(item,index) in menuData"
                 :is="(item.treeList && item.treeList.length>0)?'el-submenu':'el-menu-item'"
                 :index="item.id + ''" @click="changeRouter(item)">

        <template v-if="item.treeList&&item.treeList.length>0">
          <template slot="title">
            <i :class="item.icon" :style="{color:'#FFFFFF'}"></i>
            <span slot="title">{{ item.menuName }}</span>
          </template>

          <el-menu-item class="menu-item" v-for="(v,i) in item.treeList" :key="v.id" :index="v.id + ''"
                        @click="changeRouter(v)">
            <i :class="v.icon" :style="{color:'#FFFFFF'}"></i>
            <span slot="title">{{ v.menuName }}</span>
          </el-menu-item>
        </template>

        <template v-else>
          <i :class="item.icon" :style="{color:'#FFFFFF'}"></i>
          <span slot="title">{{ item.menuName }}</span>
        </template>

      </component>
    </el-menu>
  </el-aside>
</template>

<script>

import {menuByLoginUser,index} from "../../utils/api";

export default {
  name: "TheAside",
  props: {
    isCollapse: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  data() {
    return {
      menuData: [],
      logoIsShow: true,
      logoPath:"",
      activeIndex: localStorage.getItem('menuActiveIndex') || "",//这儿可以设置 如果没有缓存的话  可以设置为学习顾问页面 值为字符串1 暂定为空
    }
  },
  methods: {
    getMenu() {
      menuByLoginUser().then(res => {
        if (res.success) {
          this.menuData = res.data
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(err => {
        // this.$message.error("获取目录失败");
      })
    },
    changeRouter(item) {
      let menuUrl = item.menuUrl
      let menuName = item.menuName
      this.activeIndex = item.id.toString()
      localStorage.setItem('headerTitle', menuName)
      localStorage.setItem('menuActiveIndex', item.id.toString())
      this.$emit("changeRouter", menuUrl, menuName)
    },
    initLogo() {
      index().then(res => {
        if (res.success) {
          let resEntity = res.data
          this.logoPath = resEntity.logoPath
        }
      })
    }
  },
  mounted() {
    this.getMenu()
    this.initLogo()
  }
}
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}


/deep/ .el-icon-arrow-down {
  color: #FFFFFF;
}
/deep/.el-menu-item.is-active {
    background-color: rgb(51 126 204) !important;
}

/deep/ .el-menu {
    border-right: none;
}

</style>
