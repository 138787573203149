<template>
  <div>
    <el-dialog
        :show-close="false"
        :visible.sync="isNewesAactivity.isShow"
        width="30%">
      <div style="cursor: pointer;" @click="strangeSoldierChannel()">
        <div class="layui-col-md12" style="text-align: center"><span style="font-size: 25px; color: red">活动</span></div>
        <hr/>
        <div class="layui-col-md12" style="text-align: center;font-size: 18px ">
          <span style="display: block;line-height: 22px" v-html="Html.title"></span>
          <img :src='Html.url' style='max-width: 90%;height: auto' />
        </div>

      </div>
      <!--<span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
  </span>-->
    </el-dialog>
  </div>
</template>

<script>
import { setDateTime,getDateTime } from '@/utils/auth' // 验权
import AV from 'leancloud-storage'
//时间格式化
Date.prototype.Format = function (fmt) { // author: meizz
  var o = {
    "M+": this.getMonth() + 1, // 月份
    "d+": this.getDate(), // 日
    "h+": this.getHours(), // 小时
    "m+": this.getMinutes(), // 分
    "s+": this.getSeconds(), // 秒
    "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
    "S": this.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}
export default {
  name: "NewesAactivity",
  props: {
    isNewesAactivity:{
      type: Object,
      require: true,
    },
    isShowNews:{
      type:Boolean,
      require: true,
    }
  },
  data() {
    return {
      Time:'',
      NewTime:new Date().Format("yyyy-MM-dd hh:mm:ss"),
      img_open:'',
      Html:{},
      isShow:false
    }
  },
  methods: {
    automatic() {
      new AV.Query('QianXiang').get("60813780cd0f880937d656fd").then((todo) => {
        let data = todo.toJSON();
        let dataArray = data.array[0];
        if(dataArray.tips === 1){
          this.Html = dataArray;
          if(this.bjDate(dataArray.deadline,this.NewTime)){
            console.log('当前时间在设置时间范围内')
            var dateTime = getDateTime("dateTime");
            if(dateTime !== ''  && dateTime != null){
              if(dateTime !== dataArray.dateTime){
                this.$emit('updateShowNews',true)
                this.$emit('ShowNewesAactivity')
                setDateTime(dataArray.dateTime)
              } else {
                this.$emit('updateShowNews',true)
                console.log('相等')
                // return false;
              }

            } else {
              setDateTime(dataArray.dateTime)
              this.$emit('updateShowNews',true)
              this.$emit('ShowNewesAactivity')
            }
          }else{
            this.$emit('updateShowNews',false)
            console.log("当前时间超过设置时间")
            const todo = AV.Object.createWithoutData('QianXiang', '60813780cd0f880937d656fd');
            data.array[0].tips = 0;
            todo.set('array', data.array);
            todo.save();
          }
        }
      })
    },
    bjDate(date, date1) {
      let dateOne = new Date(date);
      let dateTwo = new Date(date1);
      // console.log(dateOne.getTime(),dateTwo.getTime())
      if(dateOne.getTime() - dateTwo.getTime() > 0) {
        // console.log('第一个时间大')
        return true;
      } else {
        // console.log('第二个时间大')
        return false;
      }
    },
    strangeSoldierChannel(){
      this.$emit('showKnowledge')
      this.isNewesAactivity.isShow = false;
    },
  },
  mounted() {
    this.automatic();
    setInterval(()=>{
      this.automatic()
    }, 1000 * 60 * 30);

  }
}
</script>

<style scoped>
/deep/.el-dialog__body{
  padding: 0;
}
/deep/.el-dialog__header{
  padding: 0;
}
</style>