import request from '@/utils/request.js';

const url = "/manage/system/message";

/**
 * 获取消息分页数据
 * @param params
 * @returns {*}
 */
export function listPage(params) {
    return request({
        url: `${url}/pageList`,
        method: 'GET',
        params: params
    })
}

/**
 * 获取单个消息详情
 * @param id
 * @returns {*}
 */
export function getOne(id) {
    return request({
        url: `${url}/get?messageId=${id}`,
        method: 'GET'
    })
}

/**
 * 已读消息方法
 * @param params
 * @returns {*}
 */
export function isRead(params) {
    return request({
        url: `${url}/isRead?messageId=${params}`,
        method: 'PUT'
    })
}

/**
 * 删除消息
 *
 * @param ids
 * @returns {*}
 */
export function deleteMsg(ids) {
    return request({
        url: `${url}/delete?messageIds=${ids}`,
        method: 'DELETE',
    })
}

/**
 * 点击完成方法
 * @param id
 * @returns {*}
 */
export function finish(id) {
    return request({
        url: `${url}/finish?messageId=${id}`,
        method: 'GET',
    })
}

/**
 * 校验消息上的机会当前归属人是否是发送消息时的归属人
 * @param id
 * @returns {*}
 */
export function checkOpportunity(id) {
    return request({
        url: `${url}/checkOpportunity?opportunityId=${id}`,
        method: 'GET',
    })
}

/**
 * 获取消息类型以及未读数量方法
 */
// export function getMessageTypeList() {
//     return request({
//         url: `${url}/messageType`,
//         method: 'GET'
//     })
// }

/**
 * 全部已读
 */
export function readAll() {
    return request({
        url: `${url}/readAll`,
        method: 'PUT'
    })
}

/**
 * 获取未读数量
 */
export function unreadCount() {
    return request({
        url: `${url}/unreadCount`,
        method: 'get'
    })
}