<template>
  <div>
  </div>
</template>

<script>
import backGround from '../../assets/images/CRMchengjiao450a.png';
export default {
  name: "GoodNews",
  props: {
  },
  data() {
    return {
      chengdandaji0: `<div style="padding:220px 96px 0;box-sizing:border-box;background:transparent;height: 100%;background-image:url('${backGround}');background-size:450px 550px; background-repeat: no-repeat;text-align:center;">`,
      chengdandaji1: '<span style="font: bold 23px/36px 楷体_GB2312, Helvetica, cursive ;color:#f7dba1;">',
      chengdandaji2: '</span></div>',
      content:"恭喜<span style='color:white;'></span>成功签约<br></br><span style='color:white;font-size:30px;'>元</span><br>勇闯巅峰  再创辉煌"
    }
  },
  methods:{
    goodNews(msg){
      var deptName = msg.deptName
      var accountName = msg.accountName
      var goodsName = msg.goodsName
      var price = msg.price

      this.$notify.closeAll();
      this.$notify({
        duration:7000,
        dangerouslyUseHTMLString: true,
        message: `<div style="padding:184px 66px 0;box-sizing:border-box;background:transparent;height: 100%;background-image:url('${backGround}');background-size:350px 450px; background-repeat: no-repeat;text-align:center;"><span style="font: bold 20px/32px 楷体_GB2312, Helvetica, cursive ;color:#f7dba1;">恭喜:${deptName}<span style='color:white;'>${accountName}</span>成功签约</br>${goodsName}</br><span style='color:white;font-size:30px;'>${price}元</span><br>勇闯巅峰  再创辉煌</span></div>`,
        position: 'bottom-right',
        customClass:'mainSize'
      });
    }
  },
  mounted() {
  },
}
</script>

<style lang="less">
.mainSize{
  width: 350px!important;
  height: 450px!important;
  background: transparent;
  box-shadow: none;
}
.mainSize .el-notification{
  border: none;
}
.mainSize  .el-notification__group{
  width: 350px!important;
  height: 450px!important;
}
.mainSize  .el-notification__content{
  width: 350px!important;
  height: 450px!important;
}
.mainSize  .el-notification__content p{
  height: 450px!important;
}
.el-notification__closeBtn {
  top: 32px;
  font-size: 28px;
}
</style>