<template>
    <div style="display: flex;flex-direction: column;height: 50px" >
        <el-header>
            <el-row :gutter="20" type="flex" justify="space-between" align="middle">
                <el-col :span="6">
                    <i :class="iconStr" @click="showMenu"></i>
                    <div class="dot"></div>
                    <span>{{ menuName }}</span>
                </el-col>
                <el-col :span="18" style="text-align: right">
                    <!--        <el-carousel height="60px" direction="vertical" :autoplay="true" v-if="hxurl">-->
                    <!--          <el-carousel-item v-for="item in activities" :key="item.id"-->
                    <!--                            @click.native="toActivity(item.objectId,item.content)">-->
                    <!--            <h6 class="medium hand" :style="{verticalAlign:'middle',lineHeight:'60px',margin:'0',color:'red'}">-->
                    <!--              {{ item.content }}-->
                    <!--            </h6>-->
                    <!--          </el-carousel-item>-->
                    <!--        </el-carousel>-->

                    <!--    文档搜索框    -->
                    <!--        <el-autocomplete-->
                    <!--            v-model="docParams"-->
                    <!--            :fetch-suggestions="querySearchAsync"-->
                    <!--            placeholder="查找关键字"-->
                    <!--            @select="handleSelect"-->
                    <!--        ></el-autocomplete>-->
                    <!--        <el-tooltip class="item" v-if="hxurl" effect="dark" content="试听课、商品介绍、顾问物料等在此查询下载"-->
                    <!--                    placement="bottom">-->
                    <!--          <el-button size="mini" class="knowledge" type="warning" @click="showKnowledge">知识库</el-button>-->
                    <!--        </el-tooltip>-->

                    <el-tooltip class="item" effect="dark" content="消息中心" placement="bottom">
                      <el-badge :value="unread" :hidden="unread === 0" :max="99" class="badge-item">
                        <el-button type="text" @click="showMessage" style="margin-right: 10px;">
                          <div style="display: flex;flex-direction: row;align-items: center">
                            <i class="el-icon-message" style="font-size: 20px"/> 消息中心
                          </div>
                        </el-button>
                      </el-badge>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="光荣榜" placement="bottom">
                        <el-button type="text" @click="ShowHonourRoll" style="margin-right: 10px;">
                            <div style="display: flex;flex-direction: row;align-items: center">
                                <i class="el-icon-s-data" style="font-size: 20px"/> 光荣榜
                            </div>
                        </el-button>
                    </el-tooltip>


                    <el-tooltip class="item" effect="dark" content="最新活动" placement="bottom" v-if="isShowNews">
                        <el-button type="text"><i class="el-icon-alarm-clock" @click="ShowNewesAactivity"></i></el-button>
                    </el-tooltip>

                    <el-button type="text" style="margin-right: 10px;" @click="bindWework">
                        <div style="display: flex;flex-direction: row;align-items: center">
                            <i class="el-icon-chat-dot-round" style="font-size: 20px"/> 绑定企微
                        </div>
                    </el-button>

                    <el-dropdown @command="handleCommand" trigger="click">
                        <span class="logout username">{{ userName }} <i class="el-icon-arrow-down el-icon--right"></i></span>
                        <el-dropdown-menu #default>
                            <el-dropdown-item command="passwordEdit" icon="el-icon-edit">修改密码</el-dropdown-item>
                            <el-dropdown-item command="signOut" divided icon="el-icon-switch-button">退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>

                    <el-divider direction="vertical"></el-divider>

                    <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            员工号:{{ userId }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <el-tag type="success">直呼显示名:</el-tag>
                                {{ traffic.user }}
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-tag type="success">直呼用户名:</el-tag>
                                {{ traffic.user }}
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-tag type="success">直呼口令:</el-tag>
                                {{ traffic.passwd }}
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-tag type="success">鉴权用户名:</el-tag>
                                {{ traffic.user }}
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-tag type="success">直呼域名:</el-tag>
                                39.99.227.204
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <!--        <span class="logout username">{{ userName }}</span>
                            <span class="logout split">|</span>
                            <span class="logout hand" @click="doLogout"><i class="el-icon-switch-button"></i>退出</span>-->
                </el-col>
            </el-row>
            <el-dialog title="修改密码" :visible.sync="dialogFormVisible" append-to-body :close-on-click-modal="false" width="800px">
                <el-form :model="pwform" label-width="120px" :rules="pwrules" ref="pwform">
                    <el-form-item label="手机号：" prop="mobile" required>
                        <el-input v-model="pwform.mobile" disabled autocomplete="off" style="width: 70%"/>
                    </el-form-item>
                    <el-form-item label="验证码：" prop="code" required>
                        <el-input placeholder="请输入验证码" :maxlength="9" onkeyup="value=value.replace(/\D/g,'')" v-model="pwform.code">
                            <template slot="append">
                                <el-button @click="sendSms" :disabled="sendBtn" style="background-color: #409EFF;color: white;"
                                           type="primary">发送验证码
                                </el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="密码：" prop="password" required>
                        <el-input v-model="pwform.password" autocomplete="off" style="width: 70%"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码：" prop="checkPassword" required>
                        <el-input v-model="pwform.checkPassword" autocomplete="off" style="width: 70%"></el-input>
                        <p style="font-size: 10px;color: rgb(81 81 81);padding: 0;margin: 0;line-height: 12px;width: 70%;">密码长度不少于6位，需组合大小英文字母、数字、特殊符号中的至少2种</p>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="editPassWord">确 定</el-button>
                </div>
            </el-dialog>
            <TheKnowledge :suffix="suffix" :show-obj="knowledgeObj" :knowTitle="knowTitle"></TheKnowledge>
            <Message ref="MessageBox" v-if="this.shopMessageObj.isShow" :shopMessageObj="shopMessageObj"></Message>
            <HonourRoll :isShowHonourRoll="isShowHonourRoll" v-if="isShowHonourRoll.isShow"></HonourRoll>
            <NewesAactivity :isNewesAactivity='isNewesAactivity' :isShowNews.sync="isShowNews" v-if="isNewesAactivity"
                            @ShowNewesAactivity="ShowNewesAactivity" @updateShowNews="updateShowNews"
                            @showKnowledge="showKnowledge"></NewesAactivity>
        </el-header>
        <BindingPersonalPhone :dialog-form-visible="personalPhoneDialog" :user-name="userName" @closeBindingPersonalPhoneDialog="closeBindingPersonalPhoneDialog"/>

        <bind-wework :wework="wework"></bind-wework>
    </div>
</template>

<script>
import {getUserId, getUserName,getPersonalPhone, removeInformation, removeToken} from "../../utils/auth";
import TheKnowledge from "../TheKnowledge";
import AV from 'leancloud-storage'
import * as accountApi from "@/api/system/account";
import NewesAactivity from "@/components/newestActivity/NewesAactivity";
import {getOneByUserId} from "@/api/crm/traffic";
import BindingPersonalPhone from "@/components/BindingPersonalPhone.vue";
import BindWework from "@/components/BindWework.vue";
import {unreadCount} from "@/api/system/message";

const Message = () => import("../../views/consultant/components/Message")
const HonourRoll = () => import("@/components/honourRoll/HonourRoll")

export default {
  name: "TheHeader",
  components: {BindWework, BindingPersonalPhone, Message, TheKnowledge, HonourRoll, NewesAactivity},
  props: {
    headerTitle: {
      type: String,
      require: true
    },
    hxurl: {
      type: Boolean,
      require: true
    }
  },
  data() {
    return {
        wework:{
            show:false
        },
      sendBtn: false,
      restaurants: [],
      docParams: "",
      dialogFormVisible: false,//修改密码弹窗
      pwform: {
        oldpassword: '',
        password: '',
        checkPassword: ''
      },
      pwrules: {
        mobile: [
          {required: true, message: '请输入手机号', trigger: 'blur'}
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入新密码', trigger: 'blur'}
        ],
        checkPassword: [
          {required: true, message: '请确认新密码', trigger: 'blur'}
        ]
      },
      iconStr: "el-icon-s-fold",
      isShowMenu: false,
      menuName: localStorage.getItem('headerTitle'),
      knowledgeObj: {
        isShow: false
      },
      knowTitle: '',
      activities: [],
      suffix: "",
      shopMessageObj: {
        isShow: false,
        type: 1 //1.打开主体列表 2 打开消息详情
      },
      isShowHonourRoll: {
        isShow: false
      },
      isNewesAactivity: {
        isShow: false
      },
      isShowNews: false,
      traffic: {
        user: '无',
        passwd: '无'
      },
      personalPhoneDialog: true,
      unread:0,
    }
  },
  methods: {
    getUnreadCount(){
      unreadCount().then(res=>{
        if(res.success){
          this.unread = res.data
        }
      })
    },
      /**
       * 绑定企微
       */
      bindWework(){
          this.wework.show = true
      },
    closeBindingPersonalPhoneDialog() {
        this.personalPhoneDialog = true;
    },
    sendSms() {
      this.sendBtn = true
      // 发送验证码
      accountApi.sendCode(this.pwform.mobile).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    querySearchAsync(queryString, cb) {
      let restaurants = this.restaurants;
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {

    },
    showMenu() {
      this.iconStr = this.isShowMenu ? "el-icon-s-fold" : "el-icon-s-unfold"
      this.isShowMenu = !this.isShowMenu
      this.$emit("isShowMenu", this.isShowMenu)
    },
    /*
    * 点击姓名触发事件方法
    * */
    handleCommand(command) {

      if (command === 'passwordEdit') {
          if (!this.personalPhone) {
            this.personalPhoneDialog = false;
            this.$message.warning({
                  message: "该账户未绑定登录手机号, 请联系后台人员协助绑定",
                  customClass: 'max-z-index'
              });
          }else {
              this.pwform.accountId = this.userId
              this.pwform.mobile = this.personalPhone;
              this.dialogFormVisible = true;
          }
      } else if (command === 'signOut') {
        this.doLogout()
      }
    },
    /*
    * 修改密码
    * */
    editPassWord() {
      this.$refs['pwform'].validate((valid) => {
        if (valid) {
          let pw = this.pwform.password
          let cpw = this.pwform.checkPassword
          if (pw === cpw) {
            let that = this
            accountApi.passwordUpdate(this.pwform).then(res => {
              if (res.success) {
                that.$message.success("密码更新成功,请重新登录")
                setTimeout(function () {
                  removeToken()
                  removeInformation()
                  that.$router.go(0);
                }, 1000)
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            this.$message.error("两次密码输入不一致")
          }
        }
      });
    },
    doLogout: function () {
      accountApi.logout(this.userId).then(res => {
          if (res.success) {
              removeToken()
              removeInformation()
              localStorage.removeItem("permission");
              this.$message.success("退出成功")
              this.$router.go(0);
          }
      })
    },
    showKnowledge: function () {
      this.suffix = ""
      this.knowledgeObj.isShow = true
      window._hmt.push(['_trackEvent', '按钮点击', '顶部知识库按钮链接点击']);
    },
    initActivities: function () {
      let that = this
      new AV.Query('QianXiang').get("60652f297e5fda752471dba9").then((todo) => {
        let data = todo.toJSON();
        that.activities = data.array
      })
    },
    toActivity: function (objectId, title) {
      this.suffix = `&selected=${objectId}&type=${objectId}&title=${escape(title)}`
      this.knowledgeObj.isShow = true
    },
    showMessage: function () {
      this.shopMessageObj.isShow = true
      this.shopMessageObj.type = 1
    },
    ShowHonourRoll() {
      this.isShowHonourRoll.isShow = true
    },
    ShowNewesAactivity() {
      this.isNewesAactivity.isShow = true
    },
    updateShowNews(params) {
      this.isShowNews = params
    },
  },
  computed: {
    userName: function () {
      return getUserName()
    },
    userId: function () {
      return getUserId()
    },
    personalPhone: function () {
      return getPersonalPhone()
    },
    store_headerTitle() {
      return this.$store.getters.headerTitle
    },

    // 用于监听全局的shopMessageObj
    store_shopMessageObj() {
      return this.$store.state.shopMessageObj
    }
  },
  watch: {
    store_headerTitle(n) {
      this.menuName = localStorage.getItem('headerTitle')
    },
    // headerTitle: function (newValue, oldValue) {
    //   this.menuName = localStorage.getItem('headerTitle')
    // },
    store_shopMessageObj: {
      deep: true,
      handler(n, o) {
        this.shopMessageObj = Object.assign({}, n)
        setTimeout(() => {
          // console.log(this.$refs.MessageBox)
          this.$refs.MessageBox.getMessage(n)
        }, 500)
      }
    }
  },
  mounted() {
    this.initActivities();
    this.getUnreadCount()
    setInterval(() => {
      this.getUnreadCount()
    }, 30000)
    getOneByUserId(getUserId()).then(resp => {
      if (resp.success && resp.data) {
        this.traffic = resp.data
      }
      localStorage.removeItem("trafficUser")
        if(resp.data && resp.data.user){
            localStorage.setItem("trafficUser",resp.data.user)
        }
    })
  }
}
</script>

<style scoped>
.dot {
    width: 12px;
    height: 12px;
    background: #000000;
    border: 1px solid #000000;
    border-radius: 50%;
    display: inline-block;
    line-height: 50px;
    margin: 0 10px;
}

.new-activity {
    font-size: 14px;
    color: #FF1200;
    font-weight: 500;
}

.knowledge {
    font-size: 14px;
    font-weight: 500;
    padding: 7px 10px;
}

.logout {
    font-size: 14px;
    color: #6082ED;
    font-weight: 500;
}

.hand {
    cursor: pointer;
}

/deep/ .el-dropdown {
    cursor: pointer;
    color: #409EFF;
}
.badge-item{
  margin-right: 40px;
}

/deep/ .badge-item .el-badge__content{
  top: 10px !important;
}
</style>
