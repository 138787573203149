import { render, staticRenderFns } from "./TheAside.vue?vue&type=template&id=69323623&scoped=true&"
import script from "./TheAside.vue?vue&type=script&lang=js&"
export * from "./TheAside.vue?vue&type=script&lang=js&"
import style0 from "./TheAside.vue?vue&type=style&index=0&id=69323623&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69323623",
  null
  
)

export default component.exports