<template>
    <div>
        <!-- Form -->
        <el-dialog title="绑定手机号" :visible="!dialogFormVisible" :show-close="false" width="600px"
                   @close="dialogClose">
            <el-form :model="form" style="width: 80%">
                <el-form-item label="用户名" :label-width="formLabelWidth">
                    <el-input v-model="userName" disabled autocomplete="off"/>
                </el-form-item>
                <el-form-item label="登录手机号" :label-width="formLabelWidth">
                    <el-input v-model="form.personalPhone" :disabled="phoneDisabled" autocomplete="off"/>
                </el-form-item>
                <el-form-item label="验证码" :label-width="formLabelWidth">
                    <el-input v-model="form.code" autocomplete="off">
                        <template #suffix>
                            <el-button v-if="!isSendCode" @click="sendSms" type="primary" size="small"
                                       :disabled="buttonDisabled">获取验证码
                            </el-button>
                            <el-button v-else size="small" :disabled="true">{{ sendCodeTime }}秒后重新发送</el-button>
                        </template>
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="danger" plain @click="closeBindingPersonalPhoneDialog">暂不绑定</el-button>
                <el-button type="primary" plain @click="submitForm" :disabled="submitDisabled">确认绑定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as accountApi from "@/api/system/account";

function isValidPhoneNumber(phoneNumber) {
    // 正则表达式匹配中国的手机号，格式为：1[3-9]\d{9}
    // 1开头，第二位是3-9之间的数字，后面跟着9位数字
    const regex = /^1[3-9]\d{9}$/;
    return regex.test(phoneNumber);
}

export default {
    name: "BindingPersonalPhone",
    props: {
        dialogFormVisible: {
            type: Boolean,
            default: true
        },
        userName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            form: {
                personalPhone: null,
                code: null
            },
            formLabelWidth: '120px',
            // 60s倒计时
            sendCodeTime: 60,
            sendCodeInterval: null,
            isSendCode: false
        };
    },
    computed: {
        phoneDisabled() {
            return false;
        },
        buttonDisabled() {
            return !isValidPhoneNumber(this.form.personalPhone);
        },
        submitDisabled() {
            return !this.form.code;
        },
    },
    methods: {
        dialogClose() {
            // console.log('关闭')
            clearInterval(this.sendCodeInterval)
        },
        // 创建发送验证码倒计时
        createSendCode() {
            this.isSendCode = true
            this.sendCodeInterval = setInterval(() => {
                this.sendCodeTime--
                if (this.sendCodeTime <= 0) {
                    clearInterval(this.sendCodeInterval)
                    this.sendCodeTime = 60
                    this.isSendCode = false
                }
            }, 1000)
        },
        submitForm() {
            accountApi.bindingPersonaPhone(this.form).then(resp => {
                if (resp.success) {
                    this.$parent.closeBindingPersonalPhoneDialog()
                    this.$message.success(resp.msg)
                } else {
                    this.$message.error(resp.msg)
                }
            })
        },
        sendSms() {
            // 发送验证码
            accountApi.sendCode(this.form.personalPhone).then(res => {
                if (res.success) {
                    this.$message.success(res.msg)
                    this.createSendCode()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        closeBindingPersonalPhoneDialog() {
            this.$parent.closeBindingPersonalPhoneDialog()
        }
    }
}
</script>

<style scoped>

</style>