<template>
  <el-dialog
      title="企微账号"
      :visible.sync="wework.show"
      width="60%"
      @open="getList"
  >
    <el-row>
      <el-col :span="18">
        <el-button type="primary" icon="el-icon-link" size="mini" @click="handleAdd">绑定</el-button>
        <!--https://login.work.weixin.qq.com/wwlogin/sso/login?login_type=CorpApp&appid=wwd036d82f9879e492&redirect_uri=http%3A%2F%2Fzebj.kaoshizaixian.com&state=STATE&agentid=1000033-->
      </el-col>
    </el-row>
    <el-table :data="dataList" style="width: 100%" stripe fit max-height="640" v-loading="loading">
      <el-table-column align="center" label="企微名称" prop="name"></el-table-column>
      <el-table-column align="center" label="企微账号" prop="userid"></el-table-column>
      <el-table-column align="center" label="企微部门" prop="department"></el-table-column>
      <el-table-column align="center" label="绑定时间" prop="createTime"></el-table-column>
      <el-table-column align="center" label="操作">
        <el-button type="text" @click="unpinless()">解绑</el-button>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="wework.show= false">确 定</el-button>
        </span>
  </el-dialog>
</template>

<script>
import {getWeworkAccount, unpinless} from "@/api/wework/weworkAccount";

export default {
  name: "BindWework",
  props: {
    wework: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      loading: false,
      dataList: []
    }
  },
  methods: {
    unpinless() {
      this.$confirm('您确定要解除绑定吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        unpinless().then(res => {
          if (res.code === 200) {
            this.$message.success(res.msg)
            this.getList()
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {
        this.$message.info('已取消解绑');
      });

    },
    getList() {
      getWeworkAccount().then(res => {
        if (res.code === 200) {
          this.dataList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleAdd() {
      let login_type = "CorpApp"
      let appid = "wwf19c0fe52432f3a8"
      let agentid = "1000030"
      let redirect_uri = "https://zebj.igegeda.com/wework_auth.html"
      let state = "WWLogin"
      let lang = "zh"
      let url = encodeURI(`https://login.work.weixin.qq.com/wwlogin/sso/login?login_type=${login_type}&appid=${appid}&redirect_uri=${redirect_uri}&state=${state}&agentid=${agentid}`)
      console.log(url)
      let screenWidth = window.screen.width;
      let screenHeight = window.screen.height;
      let width = (screenWidth - 200) / 2
      let height = (screenHeight - 200)
      window.open(url, '_blank', `width=${width},height=${height},left=300,top=300`)
    }
  }
}
</script>

<style scoped>

</style>
