<template>
  <el-container>
    <TheAside @changeRouter="changeRouter" :is-collapse="isShow"></TheAside>
    <el-container direction="vertical" :style="{height:'100vh'}">
      <TheHeader :headerTitle="menuName" @isShowMenu="isShowMenu" :hxurl="hxurl"></TheHeader>
      <el-main>
        <GoodNews ref="goodNew"></GoodNews>
        <router-view></router-view>
        <!--                <div class="positionButton" v-if="hxurl">-->
        <!--                    <el-tooltip class="item" effect="dark" content="营销素材" placement="left">-->
        <!--                        <el-button type="primary" icon="el-icon-folder-opened"-->
        <!--                                   @click="quickAccessKnowledge('speechcraft')"></el-button>-->
        <!--                    </el-tooltip>-->
        <!--                    <el-tooltip class="item" effect="dark" content="师资介绍" placement="left">-->
        <!--                        <el-button type="primary" icon="el-icon-user"-->
        <!--                                   @click="quickAccessKnowledge('teachers')"></el-button>-->
        <!--                    </el-tooltip>-->
        <!--                    <el-tooltip class="item" effect="dark" content="商品介绍" placement="left">-->
        <!--                        <el-button type="primary" icon="el-icon-s-management"-->
        <!--                                   @click="quickAccessKnowledge('course')"></el-button>-->
        <!--                    </el-tooltip>-->
        <!--                </div>-->
        <QuickAccessKnowledge :suffix="suffix" :knowledgeObj="knowledgeObj"></QuickAccessKnowledge>
        <el-dialog title="重要通知" :visible.sync="showImg">
          <p style="font-size: 20px;">
            为保证系统安全，所有人crm账号必须于<br/>
            <span style="color: #fb3030;">【今日18点前进行重置密码操作】！</span><br/>
            18点前未进行密码重置的用户，crm账号将无法登录，请及时重置密码，避免耽误使用重置密码操作步骤如下图：<br/>
            <el-image style="width: 100%;height: 100%" z-index="999999"
                      src="https://gegeda-crm-public.oss-cn-beijing.aliyuncs.com/image/zhengerbajing/240108C80041CDP0.png"
                      :preview-src-list="['https://gegeda-crm-public.oss-cn-beijing.aliyuncs.com/image/zhengerbajing/240108C80041CDP0.png']"></el-image>
          </p>
        </el-dialog>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import TheAside from "../../components/main/TheAside";
import TheHeader from "../../components/main/TheHeader";
import {index, agreeFrozen, turnDownFrozen} from "@/utils/api";
import {getUserId, getDataKey} from "@/utils/auth";

import GoodNews from "../../components/goodNews/GoodNews";

import QuickAccessKnowledge from "@/components/QuickAccessKnowledge";

export default {
  name: 'Home',
  components: {TheHeader, TheAside, GoodNews, QuickAccessKnowledge},
  data() {
    return {
      isShow: false,
      menuName: '',
      messageDetailObj: {},
      messageId: 0,
      suffix: '',
      knowledgeObj: {
        isShow: false,
        link: ''
      },
      goodsShow: false,
      name: '学习顾问',
      hxurl: true,
      showImg: false,
    }
  },
  methods: {
    isHuaxia() {
      let hr = window.location.href
      this.hxurl = true
      if (hr.indexOf("huaxia") > 0) {
        this.hxurl = false
      }
    },
    changeRouter(menuUrl, menuName) {
      this.$router.push(menuUrl)
      this.menuName = menuName
      this.$store.dispatch('setHeaderTitle', menuName)
    },
    isShowMenu(isShow) {
      this.isShow = isShow
    },
    initConfig: function () {
      index().then(res => {
        if (res.success) {
          let resEntity = res.data
          document.title = resEntity.netName ? resEntity.netName : "CRM"
          let linkObject = document.createElement('link');
          linkObject.rel = "shortcut icon";
          linkObject.href = resEntity.smallLogoPath ? resEntity.smallLogoPath : "";
          document.getElementsByTagName("head")[0].appendChild(linkObject);
          var _hmt = _hmt || [];
          window._hmt = _hmt; // 修改为window 全局变量
          (function () {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?" + resEntity.params;
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
          })();
        }
      })
    },
    messageDetail() {
      this.messageDetailObj.messageId = this.messageId
      //发送消息事件
      this.bus.$emit("messageDetail", this.messageDetailObj)
    },
    initWebSocket: function () {
      let dataKey = getDataKey()
      let userId = getUserId()
        console.log('initWebSocket',window.location.url)
        let wsUrl = window.location.origin.replace("https://","wss://").replace("http://","ws://").replace("8080","7998")
      // this.websocket = new WebSocket(`ws://127.0.0.1:7998/websocket/${dataKey}/${userId}`)
      this.websocket = new WebSocket(`${wsUrl}/websocket/${dataKey}/${userId}`)


      this.websocket.onopen = this.webSocketOnOpen
      this.websocket.onerror = this.webSocketOnError
      this.websocket.onmessage = this.webSocketOnMessage
      this.websocket.onclose = this.webSocketOnClose
    },
    webSocketOnOpen: function () {
      console.log("WebSocket 连接成功")
    },
    webSocketOnError: function (error) {
      console.log(error)
      console.log("WebSocket 连接失败")
    },
    webSocketOnMessage: function (e) {
      console.log("收到消息")
      let message = {}
      let messageContent = Object.assign(JSON.parse(e.data), {})
      let messageType = messageContent.messageType

      if (messageType === 100) {
        this.showImg = true
        // // 拼接内容
        // const content = `
        //               为保证系统安全，所有人crm账号必须于<span style="color: #fb3030;font-size: 13px;">【今日18点前进行重置密码操作】！</span><br/>
        //               18点前未进行密码重置的用户，crm账号将无法登录，请及时重置密码，避免耽误使用重置密码操作步骤如下图：<br/>
        //       <image
        //       onclick="openImage"
        //           style="height: 100px"
        //           src="https://gegeda-crm-public.oss-cn-beijing.aliyuncs.com/image/zhengerbajing/240108C80041CDP0.png">
        //         </image>`
        //
        // this.$notify({
        //   title: messageContent.title,
        //   dangerouslyUseHTMLString: true,
        //   message: content,
        //   type: "warning",
        //   duration: 0
        // })
        // return;
      }

      if (messageType === 13) {
        let start = messageContent.contentText.indexOf('【') + 1;
        let end = messageContent.contentText.indexOf('】');
        let btnId = messageContent.contentText.slice(start, end);
        this.$store.dispatch('message/changeMessageType13Id', btnId)
      }
      let isNotify = messageContent.isNotify
      let notifyType = messageContent.notifyType
      let isAutoClose = messageContent.isAutoClose
      message.id = messageContent.id
      message.title = messageContent.title
      message.isRead = messageContent.isRead
      if (messageType && isNotify === 1) {
        if (isAutoClose === 1) {
          this.$notify({
            title: messageContent.title,
            message: messageContent.contentText ? messageContent.contentText : '',
            type: notifyType,
            duration: 5000
          })
        } else {
          this.$notify({
            title: messageContent.title,
            message: messageContent.contentText ? messageContent.contentText : '',
            type: notifyType,
            duration: 0
          })
        }
        window.localStorage.setItem("message-new", JSON.stringify(message))
      } else if (messageType && isNotify !== 1) {
        window.localStorage.setItem("message-new", JSON.stringify(message))
      } else {
        this.$refs.goodNew.goodNews(messageContent);
      }

    },
    webSocketOnClose: function () {
      console.log("WebSocket 连接关闭")
      this.initWebSocket()
    },
    /**
     * 同意机会冻结
     */
    agreeFrozen: function (telephone, opportunityId) {
      let userId = getUserId()
      agreeFrozen(userId, telephone, opportunityId, userId)
    },
    /**
     * 拒绝机会冻结
     */
    turnDownFrozen: function (opportunityId, telephone) {
      let userId = getUserId()
      turnDownFrozen(opportunityId, userId, telephone)
    },
    quickAccessKnowledge(name) {
      this.suffix = `&name=${name}`
      if (name === 'speechcraft') {
        this.knowledgeObj.link = 'http://wechat.front.igegeda.com/smooth-communication/knowledge_phone/knowledge_new.html'
        window._hmt.push(['_trackEvent', '按钮点击', '电销话术按钮链接点击']);
      } else if (name === 'teachers') {
        this.knowledgeObj.link = 'http://wechat.front.igegeda.com/smooth-communication/knowledge/teacherIntroduction.html'
        window._hmt.push(['_trackEvent', '按钮点击', '师资介绍按钮链接点击']);
      } else if (name === 'course') {
        this.knowledgeObj.link = 'http://wechat.front.igegeda.com/smooth-communication/knowledge/courseIntroduction.html'
        window._hmt.push(['_trackEvent', '按钮点击', '商品介绍按钮链接点击']);
      }
      this.knowledgeObj.isShow = true
    },
  },
  mounted() {
    this.initConfig()
    this.initWebSocket()
    this.isHuaxia()
  }
}
</script>

<style scoped>
.el-header {
    height: 50px;
    line-height: 50px;
}

.el-aside {
    background-color: #409eff;
    color: #333;
}

.el-main {
    background-color: #E9EEF3;
    color: #333;
    height: calc(100vh - 50px);
    padding: 10px;
}


.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
    line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
    line-height: 320px;
}

.positionButton {
    width: 52px;
    height: auto;
    position: fixed;
    right: 0;
    top: 56px;
    z-index: 99999;
    opacity: .7;
}

.positionButton button {
    margin: 5px 0;
    font-size: 20px;
    padding: 8px 15px;
}
</style>
