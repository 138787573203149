import request from '@/utils/request.js';

// 获取企业微信账号列表
export function getWeworkAccount() {
    return request({
        url: `/workTest/wework/account/getWeworkAccount`,
        method: 'get'
    })
}

// 解绑
export function unpinless() {
    return request({
        url: `/workTest/wework/account/unpinless`,
        method: 'put'
    })
}

