<template>
  <el-drawer
      :visible.sync="showObj.isShow"
      :direction="direction"
      :with-header="false"
  >
    <iframe id="knowldge" ref="questionBack" height="100%" width="100%" scrolling="auto"
            :src="url"
            frameborder="0"></iframe>
  </el-drawer>
</template>

<script>
import {getUserId, getUserName, getDataKey,getInformation} from "../utils/auth";

export default {
  name: "TheKnowledge",
  props: {
    showObj: {
      type: Object,
      require: false,
      default: () => {
        return {
          isShow: false
        }
      }
    },
    suffix: {
      type: String,
      default: "",
      require: false
    }
  },
  data() {
    return {
      direction: 'rtl',
      getInformation:getInformation(),
      title:document.title,
    }
  },
  computed: {
    url: function () {
      getUserId()
      let dataKey = getDataKey()
      let userId = getUserId()
      let prefix = window.location.host
      if(this.suffix){
        return `http://wechat.front.igegeda.com/smooth-communication/knowledge/material_detail.html?url=${prefix}&userid=${dataKey}_${userId}${this.suffix}&nickName=${escape(this.getInformation.nickName)}&telephone=${this.getInformation.telephone}&institution=${this.title}`
      }else{
        return `http://wechat.front.igegeda.com/smooth-communication/knowledge_phone/knowledge_new.html?url=${prefix}&userid=${dataKey}_${userId}${this.suffix}&nickName=${escape(this.getInformation.nickName)}&telephone=${this.getInformation.telephone}&institution=${this.title}`
      }
    }
  }
}
</script>

<style scoped>

</style>
